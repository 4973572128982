.cultural-safaris-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1679467346/WhatsApp_Image_2023-03-21_at_23.28.38_wnddza.jpg");

  background-size: cover;
  background-position: center;
 
  margin-bottom: 50px;
}

.cultural-safaris-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.cultural-safaris-title h1 {
  margin-top: 100px;
  font-family: "Roboto";
  font-weight: 900;

  color: #ff4838;

  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cultural-safaris-desc {
  margin: 30px 10%;
}
