.travelwithus{

  text-align: justify;
}

.tanzania-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;
  margin-bottom: 100px;
}

.travelwithus-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.travelwithus-title h1 {
  margin-top: 100px;
  font-family: "Roboto";
  font-weight: 900;

  color: #eac27a;

  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-tanzania {
  margin: 0% 10%;
}

.tanzania-tour-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

.travelwithus-header {
  margin: 20px 5%;
}

.travelwithus-body {
  display: flex;
  flex-wrap: wrap;
}

.travelwithus-info {
  width: 400px;
}

.travelwithus-item {
  display: flex;
  flex: 1;
}

.front-icon {
  height: 70px;
  margin: 20px;
  width: 70px;
  border: 2px solid #4444;
  padding: 20px;
  border-radius: 50%;
  transition: all 1s ease;
}

.front-icon:hover {
  cursor: pointer;
  transform: rotate(180deg);
}

.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}
