.booking-process{

    margin: 20px;
    height: auto;
    position: relative;
}

.booking-header{

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 900;
}

.process-item{

    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 20px;
    font-weight: 900;
}

.process-item img{

    height: 250px;
    margin-left: 50px;
    width: 350px;
    object-fit: contain;
}