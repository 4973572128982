.gallery-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;


}

.gallery-name h1 {
  padding-top: 150px;
  font-family: "Roboto";
  font-weight: 900;
  font-size: 40px;
 color: #ff4838;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.gallery {
  height: auto;
  width: 100vw;

  display: flex;
  flex-wrap: wrap;
  margin: 50px 10px;
}

.gallery-image {
  margin: 10px;
  height: 30%;
  width: 30%;
}
