.luxury-africa-safaris{}

.luxury-africa-safaris-background {
    height: 50vh;
    width: 100vw;
    background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");
  
    background-size: cover;
    background-position: center;

    margin-bottom: 50px;
  }
  
  .luxury-africa-safaris-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .luxury-africa-safaris-title h1 {
    margin-top: 100px;
    font-family: "Roboto";
    font-weight: 900;
  
    color: #eac27a;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .luxury-africa-safaris-desc {
    margin: 30px 10%;
  }
  