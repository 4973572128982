.where-heading{

    margin: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    color: #eac27a;
}

.where-background{

    height: 50vh;
    width: 100vw;
    background-image: linear-gradient(to left, rgba(0,0,0,0.3), rgba(0,0,0,0.3)),
    url('https://cdn.pixabay.com/photo/2014/12/12/19/45/lion-565820__340.jpg');
   
    background-size: cover;
    background-position: center;
    margin-bottom: 100px;
}

.where-title{

    display: flex;
    align-items: center;
    justify-content: center;
   padding: 10px;
   
   
  
}


.where-title h1{

margin-top: 100px;
font-family: 'Roboto';
font-weight: 900;

color: #fff;
background-color: rgba(0,0,0,0.3);
 padding: 20px;
display: flex;
align-items: center;
justify-content: center;
}
.places{

    margin: 50px;
    width: 50%;
}

.places img{

    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: 20px 0px;
}

.places span{

   margin: 20px 0px;
   font-weight: 300;
}