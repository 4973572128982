.packagelist-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;
  margin: 0px 0px;
}


.package-search{

  padding: 20px;
  width: 50%;
  margin: 15% 20%;
  border: none;
  outline: none;
  font-weight: 600;
 

}

.packagelist-title h1 {
  margin-top: 100px;
  font-family: "Roboto";
  font-weight: 900;

  color: #eac27a;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filters-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}

.filters {
  display: flex;
  align-items: center;
}

.filters span {
  font-weight: 900;
  font-size: 20px;
  margin: 0px 20px;
}

.filters select {
  width: 200px;
  margin-right: 20px;
}

.sort {
  display: flex;
}

.sort span {
  font-weight: 900;
  font-size: 20px;
  margin-right: 20px;
}

.sort select {
  width: 200px;
  margin-right: 20px;
}

@media (max-width: 1200px) {

  .package-search{

    padding: 20px;
    width: 70%;
    margin: 40% 20%;
    border: none;
    outline: none;
    font-weight: 600;
   
  
  } 

  .filters-list {
    display: flex;
  
    flex-direction: column;

   
  }

  .filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
  }

  .search-icon{

    display: none;
  }
}
