.slide-item {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1695637121/Home_apsd5z.jpg");
  background-size: cover;
  background-position: center;
}
a{
  text-decoration: none;
  color: inherit;
}
.slide-item1 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758077/wildbeast2_fhh5hl.jpg");
  background-size: cover;
  background-position: center;
}

.slide-header {
  font-size: 10px;
}

.slide-item2 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758075/gorilla_rsmtct.jpg");
  background-size: cover;
  background-position: center;
}
.slide-item3 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758077/mountkenya_ue9zfq.jpg");
  background-size: cover;
  background-position: center;
}

.slide-item4 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758073/boat-in-the-sea_q9c27c.jpg");
  background-size: cover;
  background-position: center;
}
.slide-item5 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1680261334/IMG-20230331-WA0005_l2mwsr.jpg");
  background-size: cover;
  background-position: center;
}

.slide-item6 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758073/cheetah-desert_siwnx9.jpg");
  background-size: cover;
  background-position: center;
}

.slide-item7 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758076/helicopter_eddot1.jpg");
  background-size: cover;
  background-position: center;
}

.slide-item8 {
  height: 100vh;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url("https://res.cloudinary.com/dap91fhxh/image/upload/v1677758076/people-on-the-boat_hx2zsz.jpg");
  background-size: cover;
  background-position: center;
}
.slide-detail {
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-header {
  font-size: 37px;
  margin: 0;
  padding: 11px 0;
  line-height: 1;
  font-weight: 700;
  color: #eac27a;
  letter-spacing: 1px;
  font-family: "Cookie", cursive;
}
.slide-cont {
  width: 80%;
  padding: 5px;

  color:#eac27a;
  text-align: center;
  margin: 10px;
}

.slide-cont h4 {
  font-size: 35px;
  font-weight: 900;
}

.slide-cont span {
  font-size: 20px;
}

.slide-buttons {
  display: flex;
  margin-top: 35px;
}

.adventure {
  margin-right: 20px;
  background-color: #eac27a;
  border: none;
  color: #fff;
  font-weight: 900;
  padding: 15px;
  cursor: pointer;
  width: 250px;
}
.adventure:hover {
  border: 2px solid #eac27a;
  background-color: transparent;
  color: #eac27a;
}
.take-tour {
  background-color: #1c1c1c;
  color: #eac27a;
  padding: 15px;
  margin: 20px;
  cursor: pointer;
  font-weight: 900;
  width: 250px;
  border: none;
  outline: none;
}
a {
  color: inherit;
  text-decoration: none;
}

.take-tour:hover {
  color: #1c1c1c;
  background-color: #eac27a;
  border: none;
  outline: none;
}

.featured-search {
  width: 50%;
  background-color: #1c1c1c;
  padding: 15px;
  margin-top: 25px;
}

.featured-search input{
  background-color: #1c1c1c;
  color: #eac27a;
}
.destination-search {
  outline: none;
  border: none;
  width: 50%;
  padding: 10px;
  font-size: 16px;
}
.search-icon {
  color: #eac27a;
  font-size: 25px;
}
.search-button {
  background-color: #eac27a;
  outline: none;
  padding: 10px;
  color: #1c1c1c;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100px;
  font-size: 15px;
}

@media (max-width: 768px) {
  .slide-header {
    font-size: 27px;

    line-height: 1;
    font-weight: 700;
    color: #eac27a;
    letter-spacing: 1px;
    font-family: "Cookie", cursive;
  }

  .slide-cont {
    width: 100%;
    padding: 5px;
    color: #eac27a;
    text-align: center;
    margin: 10px;
  }
  .slide-buttons {
    display: flex;
    flex-direction: column;
  }
  .slide-detail {
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .featured-search {
    width: 100%;
    background-color: #1c1c1c;
    color:#eac27a;
    padding: 15px;
  }
  .destination-search {
    outline: none;
    border: none;
    width: 50%;
    padding: 10px;
    font-size: 16px;
  }
}
