.cart {
  position: relative;
  background-color: whitesmoke;
}

.cart-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}

.cart-name {
  padding-top: 130px;
  font-family: "Roboto";
  font-weight: 900;
  font-size: 40px;
  color: #eac27a;;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 900;
  margin: 20px 0px;
}

.cart-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 900;

  margin: 30px 0px;
}

.selected-item img {
  height: 100px;
  width: 100px;
}

.selected-item-desc h2 {
  font-weight: 900;
  font-size: 15px;
}

.selected-item-desc input {
  width: 300px;
}

.selected-item-price {
  font-weight: 900;
}

.empty-cart {
  margin: 0px 40px;
  font-weight: 900;
  font-size: 20px;
  color: #333;
}
.selected-cart {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.add {
  cursor: pointer;
  margin-right: 20px;
}

.remove {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}

.delete {
  color: #ff4838;
  cursor: pointer;
  font-size: 20px;
}

.coupon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}

.coupon input {
  padding: 5px;
}

.coupon button {
  background-color:#1c1c1c;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: #eac27a;
  font-weight: bold;
  margin-left: 30px;
}

.cart-totals {
  width: 60vw;
  margin: 50px;
  border: 1px solid #444;
  padding: 10px;
  border-radius: 10px;
  margin-left: 20%;
  height: 40vh;
}
hr {
  width: 60%;
}
.cart-totals h2 {
  font-size: 20px;
  font-weight: 900;
}

.subtotals {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.subtotals h2 {
  font-size: 20px;
  font-weight: bold;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.total h2 {
  font-size: 20px;
  font-weight: bold;
}

.proceed {
  background-color: #ff4838;
  color: #fff;
  border: none;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: bold;
}

.agree-policies {
  height: 100%;
text-align: justify;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
}

.policy {
  margin: 10% 30%;
  height: auto;
  width: 40%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  padding: 20px;
  border-radius: 5px;
}
.terms-conditions{
  cursor: pointer;
  text-decoration: underline;
  
}

.tearms {
  margin: 40px;
  font-weight: bold;
}
.policy input {
  margin-right: 20px;
}

.policy button {
  padding: 5px;
  border: none;
  background-color: #1c1c1c;
  font-weight: bold;
  cursor: pointer;
  margin: 20px;
  color:#eac27a;
}

.close-terms {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

a {
  color: inherit;
  margin-top: 10px;
}
.close-icon {
  color: #eac27a;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
  font-weight: 900;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.cart-container {
  display: flex;
  box-sizing: border-box;
  display: flex;

  justify-content: center;
}

.cart-packages {
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  width: 80%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
}

.cart-packages h5 {
  font-size: 20px;
  margin: 10px;
  font-weight: 900;
}

.cart_packages_info {
  display: flex;
  font-weight: 700;
}

.cart_packages-item_desc {
  color: #444;
  width: 40%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.cart_packages-item_desc span {
  margin: 10px;
  font-size: 18px;
}

.cart_packages-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}

.cart_packages-item img {
  width: 30%;
  height: 30%;
  object-fit: contain;
}
.cart-subtotal {
  width: 300px;
  height: 300px;
  margin: 10px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
}

.cart-subtotal h5 {
  padding: 15px;
  font-weight: 500;
  color: #333;
}

hr {
  width: 100%;
}

.cart-wrapper {
  max-width: 1074px;
  display: flex;
}

.cart-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-summary span:nth-child(1) {
  font-weight: bold;
  color: #444;
  margin: 10px;
}
.cart-summary span:last-child {
  font-weight: 900;
  font-size: 20px;
  margin: 10px;
}

.cart-subtotal button {
  width: 90%;
  background-color: #1c1c1c;
  color: #eac27a;
  font-size: 20px;
  padding: 10px;
  border: none;
  margin: 10px 5px;
}
.cart_package-item_price {
  width: 100px;
  font-weight: 900;
}

@media (max-width: 1200px) {
  .cart-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cart_packages_info {
    display: flex;
    font-weight: 700;
    flex-direction: column;
  }
  .cart_packages-item{
    display: flex;
    flex-direction: column;
  }
  .cart_packages_info{
    display: flex;
    flex-direction: column;
  }
  .cart_packages-item_desc{

    width: 100%;
  }

  .cart-subtotal {
    width: 90%;
    height: 300px;
    margin: 10px;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  }

  .cart-packages {
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 90%;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  }

  .policy {
    margin: 40% 2%;
    height: auto;
    width: 95%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
    padding: 20px;
    
  }
}
