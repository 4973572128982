.diy-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;

  margin-bottom: 50px;
}

.diy-name {
  padding-top: 150px;
  font-family: "Roboto";
  font-weight: 900;
  font-size: 40px;
  color: #eac27a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.diy-description {
  margin: 5% 10%;
  text-align: justify;
}

.diy-book {
  display: flex;
  flex-direction: column;
  margin: 0% 10%;
}

.diy-book input,
select {
  width: 50%;
  padding: 10px;
  cursor: pointer;
  margin: 20px 0px;
}

.diy-book button {
  border: none;
  background-color: #1c1c1c;
  color: #eac27a;
  width: 50%;
  margin-bottom: 30px;
  padding: 10px;
}

.invalid {
  border: 2px solid red;
}

.error-message {
  color: red;
}

.form-fill-error {
  background-color: red;
  color: #fff;
  margin: 20px;
  padding: 5px;
  font-size: 20px;
}

@media (max-width: 1200px) {
  .diy-book input,
  select {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    margin: 20px 0px;
  }

  .diy-description {
    margin: 10% 10%;
  }

  .diy-background {
    height: 50vh;
    width: 100vw;
    background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

    background-size: cover;
    background-position: center;

    margin-bottom: 30px;
  }
  .diy-name {
    padding-top: 200px;
    font-family: "Roboto";
    font-weight: 900;
    font-size: 30px;
    color: #eac27a;;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
