.navbar-normal {
  width: 100%;
  color: #eac27a;
  font-size: 15px;
  height: 200px;
  position: fixed;
  top: 0;
  z-index: 2;
  background: linear-gradient(to top, transparent 0%);

  &.scrolled {
    background-color: rgb(0, 0, 0, 0.9);
  }

  .uppercontainer {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 15px;
    }

    .navbar-email {
      margin-right: 1%;
    }
    .navbar-phone {
      span {
        margin-right: 20px;
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    .logo {
      padding: 10px;
      object-fit: cover;
      cursor: pointer;
      height: 130px;
      width: 130px;
      border-radius: 50%;
    }
    .left {
      display: flex;
      align-items: center;
      flex: 0.01;
      span {
        cursor: pointer;
      }
    }
    .center {
      display: flex;
      align-items: center;
      position: relative;
      flex: 6;

      .navbar-collapse {
        position: sticky;
        top: 0;
        font-size: 18px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .navbar-dark .navbar-nav .nav-link {
        color: black;
      }

      .link-nav {
        color: #eac27a;
        transition: all 0.2s linear;
        padding-left: 10px;
        font-family: "Poppins";
        font-size: 17px;
        
        text-transform: none !important;
      }

      .me-auto {
        font-size: 22px;

        display: flex;
        align-items: center;
        justify-content: space-evenly;

        font-family: "PT Sans", Arial, Helvetica, sans-serif;
      }
      a {
        color: inherit;
        text-decoration: none;
      }

      span {
        cursor: pointer;
        color: inherit;
        text-decoration: none;
      }

      span:hover {
        color: #d1411e;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }
    .search {
      margin-top: 10px;
      cursor: pointer;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      display: flex;
      align-items: center;
      flex: 0.2;
      justify-content: flex-end;
      cursor: pointer;

      .icon {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 767px) {
  .navbar {
   background-color: rgb(254, 250, 250);
    border-radius: 10px;
  }
 
  .logo {
    padding: 10px;
    object-fit: cover;
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .uppercontainer {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 20px;

    span {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .container {
    padding-top: 30px;
    

    .left {
      background-color: red;
    }

    .center {
      flex: 0.2;
    }

    .right {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      cursor: pointer;
    }

    img {
      height: 50px;
      width: 50px;
    }
    
    .logo {
      padding: 10px;
      object-fit: cover;
      cursor: pointer;
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }

  
}

@media (min-width: 768px) {
  .navbar-dark .navbar-toggler {
    color: #fff;
    border-color: #fff;
    padding: 20px;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
    padding: 20px;
  }

  .navbar-dark .navbar-brand {
    color: #fff;
    padding: 20px;
  }
  .logo {
    padding: 10px;
    object-fit: cover;
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}
