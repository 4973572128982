.contact-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}

.contact-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.contact-title h1 {
  margin-top: 100px;
  font-family: "Roboto";
  font-weight: 900;
  color: #eac27a;;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}
.contact-desc h2 {
  font-family: "Roboto";
}
.contact-enquiry {
  display: flex;
  align-items: center;
  margin: 20px 17%;
}
.contact-item {
  margin: 20px;
}
.contact-telephone {
  background-color: #1c1c1c;
  color: #eac27a;
  padding: 20px;
  font-size: 20px;
}

.contact-icon {
  font-size: 35px;
}

.contact-item h2 {
  font-family: "Roboto";
  font-size: 20px;
}
.contact-form {
  display: flex;
  flex-direction: column;
  margin: 20px 0%;
  background-color: transparent;
  padding: 10px;
  flex: 1;
}

.contact-form input,
textarea {
  width: 100%;
  padding: 5px;
  margin: 20px 0px;
}

.contact-form button {
  background-color:#1c1c1c;
  border: none;
  cursor: pointer;
  color:#eac27a;
  font-weight: 900;
  padding: 10px;
  width: 100%;
  margin: 20px 0px;
}

.contact-form-map {
  display: flex;
  align-items: center;
}

.contact-map {
  flex: 1;
  width: 80%;
}

@media (max-width: 1200px) {
  .contact-form-map {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-map {
    flex: 1;
    width: 100%;
  }

  .contact-map {
    flex: 1;
    width: 100%;
  }

  .contact-form input,
  textarea {
    width: 100%; 
    margin: 20px 0px;
  }

  .contact-enquiry {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10%;
  }
  
  .contact-desc span{

    width: 90%;
  }

  .contact-title h1 {
    margin-top: 180px;
    font-family: "Roboto";
    font-weight: 900;
    color: #eac27a; 
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}
