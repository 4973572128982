.rwanda{

  text-align: justify;
}

.rwanda-background {
  height: 50vh;
  width: 100vw;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");

  background-size: cover;
  background-position: center;
  margin-bottom: 100px;
  }
  
  .rwanda-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .rwanda-title h1 {
    margin-top: 150px;
    font-family: "Roboto";
    font-weight: 900;
  
    color: #eac27a;
   
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-rwanda{

    margin: 0% 10%;
  }

  .rwanda-tour-header{

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
  }