.team {
  height: auto;
  width: 100vw;
  margin-bottom: 30px;
}
.team-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 50px;
  font-weight: 900;
  color: #333;
}

.team-header h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  margin: 50px;
  color: #333;
}
.team-header span{
  color: #fff;
}
.team-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 0px 50px;
}

.team-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-bottom: -50px;
}

.name {
  background-color: #1c1c1c;
  color: #eac27a;
  padding: 10px;
  margin: 0px 10px;
  width: 60%;
  cursor: pointer;
  transition: all 1s ease;
}

.name:hover {
  transform: scale(1.1);
}

.image img {
  height: 500px;
  width: 90%;
  object-fit: cover;
}


