.package {
  margin: 50px 20px;
}

.package-head {
  margin: 20px 0px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
}

.package-detail {
  font-size: 500;
  font-size: 18px;
  width: 100%;
}
.package-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.package-item{
  height: 500px;
  width: 30%;
  margin: 10px;
}
.package-container{

 width: 100%;
 height: 80%;
  position: relative;
  transition: all 1s ease;
}

.package-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
transition: all 0.5s ease;
 
}
.package-duration{
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  background-color: #1c1c1c;
  padding: 5px;
  margin: 10px 0px;
  color: #eac27a;;
  font-weight: 900;
}

.package-item:hover  .package-info{

  display: flex;
  cursor: pointer;
  transition: all 0.5s ease;
  transform: scale(1.03);
}

.package-item:hover .package-container img{

  transform: scale(1.03);
}

.package-info{
 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  align-items: flex-end;
  transition: all 1s ease;
}

.package-info-button{

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}



.package-desc h2{
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 22px;
  margin: 10px 0px;
}

.package-info-button span{

  color: #fff;
  font-weight: 900;
  width: 100px;
  margin: 2px 10px;
}
.package-info-button button{

  border: none;
  cursor: pointer;
  background-color: #1c1c1c;
  color: #eac27a;;
  font-weight: 900;
  padding: 5px;
  margin-left: 230%;
  margin-bottom: 10%;
  width: 100px;
}


a {
  color: inherit;
  text-decoration: none;
}

.view{

  display: flex;
  align-items: center;
  justify-content: center;
}
.view-all{

 padding: 10px;
 border: none;
 margin: 30px 0px;
 width: 150px;
 font-size: 20px;
background-color: #1c1c1c;;
color: #eac27a;
cursor: pointer;
}

a{
  text-decoration: none;
  color: inherit;
}

@media (max-width: 576px) {

  .package-body {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .package-item{
    height: 500px;
    width: 100%;
    margin: 20px 0px;
    position: relative;
  }

  .package-info-button{

    display: flex;
    align-items: center;
    
  }

  .package-info-button button{

    border: none;
    cursor: pointer;
    background-color: #ff4838;
    color: #fff;
    font-weight: 900;
    padding: 5px;
    margin-left: 210%;
    margin-bottom: 10%;
    width: 100px;
  }
}

@media (min-width: 576px) { 
 
  .package-info-button{

    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .package-item{
    height: 500px;
    width: 45%;
    margin: 20px 0px;
    position: relative;
    margin: 10px;
  }
}

@media (min-width: 1200px) {

  .package-item{
    height: 500px;
    width: 30%;
    margin: 10px;
  }
 }

