.blog{
    margin: 0px 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: justify;
}

.blog h2{

    margin: 30px;
    width: 500px;
}

.blog-item{

    width: 50%;
    display: flex;
    margin: 10px;
    flex-direction: column; 
}

.blog-item img{

    height: 450px;
    width:500px;
    
    margin: 10px;
    object-fit: cover;
    transition: all 1s ease;
    cursor: pointer;
}

.blog-item img:hover{
    transform: scale(1.1);
   
}

.blog span{

width: 500px;
margin: 20px;
font-size: 15px;
font-weight: 200;
}
.blog-item button{
    cursor: pointer;
    width: 30%;
    border: none;
    padding: 10px;
    font-weight: bold;
}

a{
    text-decoration: none;
    color: inherit;
}