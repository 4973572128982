.trending{

    height: 100vh;
    width: 100vw;
}

.treading-header{

    display: flex;
    align-items: center;
    justify-content: center;
     font-weight: 900;
     margin: 50px;
    color: #333 !important;

   
}

a{

    color: inherit;
    text-decoration: none;
}

.trending-body{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
}

.trending-item{

    display: flex;
    flex-direction: column;
    margin: 20px;

    width: 25%;
    height: 30%;
    
}

.trending-item img{

    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: all 1s ease;
}
.trending-item img:hover{

    transform: scale(1.1);
}
.trending-title{

    font-weight: 900;
    margin-top: 20px;
    font-size: 22px;
}

.trending-pricing{

    font-weight: 900;
    font-size: 20px;
    margin: 20px 0px;
}

.btn-trending{

    border: none;
    padding:5px;
    cursor: pointer;
    font-weight: 900;
    font-size: 15px;
    
}