.policy-background {
    height: 50vh;
    width: 100vw;
    background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("https://images.pexels.com/photos/2674052/pexels-photo-2674052.jpeg?auto=compress&cs=tinysrgb&w=600");
  
    background-size: cover;
    background-position: center;
    margin-bottom: 100px;
  }
  
  .policy-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .policy-title h1 {
    margin-top: 100px;
    font-family: "Roboto";
    font-weight: 900;
  
    color: #eac27a;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .policy-body{
    margin: 5%;
  }

  .policy-item{

    margin: 5%;
  }

  .policy-item h2{

    font-family: 'Roboto';
  }